import React from 'react';
import '../css/videoCards.css';

const VideoCards = ({ imgSrc, title, duration, status }) => {
  return (
    <div className='shadow-lg video-card-wrapper relative'>
      <div className='flex justify-center pt-3 relative'>
        <img
          src={imgSrc}
          alt={title}
          className='w-full object-cover video-thumbnail'
        />
        {/* Duration Displayed on Bottom Left of Thumbnail */}
        <div className='absolute bottom-0 left-4 video-duration'>
          {duration}
        </div>
      </div>

      {/* Truncated Title with Hover Effect */}
      <h2
        className='mt-4 text-lg font-semibold video-card-title truncate-title'
        title={title}
      >
        {title}
      </h2>

      {/* Status Section */}
      <p
        className={`absolute bottom-0 left-0 m-2 ${
          status === 'completed'
            ? 'video-card-status-completed'
            : 'video-card-status-in-process'
        }`}
      >
        {status === 'completed' ? (
          <i className='fa-solid fa-check mr-1'></i>
        ) : (
          <i className='fa-regular fa-clock mr-1'></i>
        )}
        {status}
      </p>
    </div>
  );
};

export default VideoCards;
