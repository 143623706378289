import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Navbar from '../components/Navbar';

import '../css/videoPlay.css';

import sample_video from '../img/sample_video.mp4';

import SingleSelectDropdown from '../childComponents/dropdown/SingleSelectDropdown';
import KeywordDensity from '../childComponents/keyword/KeywordDensity';
import ColorReferenceChart from '../childComponents/ColorReferenceChart';

const topics = [
  'Control Dramas in Intro',
  'Aloof Drama',
  'Poor Me Drama',
  'Angry Me Drama',
  'Yielding in Love',
  'Understanding Control Dynamics',
  'Embracing Love as Intrinsic',
  'Lover in You',
  // 'Understanding Control Dynamics',
  // 'Embracing Love as Intrinsic',
  // 'Lover in You',
];

const languages = [
  { name: 'English', value: 'english' },
  { name: 'Hindi', value: 'hindi' },
  { name: 'Kannada', value: 'kannada' },
  { name: 'Spanish', value: 'spanish' },
  { name: 'Deutch', value: 'deutch' },
  { name: 'Japanese', value: 'japanese' },
  { name: 'French', value: 'french' },
];

const summary =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';

const VideoPlay = () => {
  const [videoDetails, setVideoDetails] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [showColorChart, setShowColorChart] = useState(false); // New state for chart visibility

  useEffect(() => {
    const videoId = localStorage.getItem('videoId'); // Fetch videoId from local storage

    if (videoId) {
      const fetchVideoDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URI}/api/process-video?videoId=${videoId}`
          );
          setVideoDetails(response.data);
        } catch (error) {
          console.error('Error fetching video details:', error);
          toast.error('Error fetching video details');
        }
      };

      fetchVideoDetails();
    } else {
      toast.error('No video found');
    }
  }, []);

  if (videoDetails) {
    console.log(videoDetails);
  }

  useEffect(() => {
    setVideoTitle(localStorage.getItem('videoTitle'));
  }, []);

  // Function to handle card click and store title in local storage
  const handleTopicClick = (topic) => {
    localStorage.setItem('videoTopic', topic.topic_name);
    localStorage.setItem('topicCount', videoDetails.video_topics.length);
  };

  // Function to handle downloading the transcription
  const handleDownloadTranscription = () => {
    if (videoDetails && videoDetails.video_transcription) {
      const transcriptionForLanguage = videoDetails.video_transcription.find(
        (transcription) => transcription[selectedLanguage]
      );

      if (transcriptionForLanguage) {
        const transcriptionText =
          transcriptionForLanguage[selectedLanguage].join('\n\n');
        const blob = new Blob([transcriptionText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${
          videoTitle + '_transcription_' + selectedLanguage || 'transcription'
        }.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        toast.error('No transcription available for the selected language');
      }
    } else {
      toast.error('No transcription available to download');
    }
  };

  return (
    <div>
      <Navbar />
      <div className='container-video-detail'>
        {/* Video and Transcription */}
        <div className='part1 '>
          <div className='media-player-wrapper flex justify-center'>
            <div className='media-player'>
              {videoTitle && <h2 className='video-title'>{videoTitle}</h2>}
              {videoDetails && videoDetails.video_url ? (
                <video controls className='video-element'>
                  <source src={videoDetails.video_url} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p>Loading video...</p>
              )}
            </div>
          </div>

          <div className='transcription-wrapper mt-5'>
            <div className='flex justify-between items-center'>
              <div>
                <span className='transcription-heading'>Transcriptions</span>
              </div>
              <div className='flex items-center justify-center'>
                <SingleSelectDropdown
                  lable={'Language'}
                  value={selectedLanguage}
                  setValue={setSelectedLanguage}
                  list={languages}
                  sx={{
                    '& .MuiSelect-select': {
                      padding: '5px 32px 5px 15px !important',
                    },
                    // You can add more custom styles here if needed
                  }}
                />

                <button
                  className='download-transcription-button'
                  onClick={handleDownloadTranscription}
                >
                  Download
                </button>
              </div>
            </div>
            <hr className='media-player-hr' />
            <div
              className='keyword-density-wrapper'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Ensures space between keywords and icon
              }}
            >
              {/* Optional: Add a label for keyword density */}
              {/* <span className='keyword-density-label'>Keyword density:</span> */}

              {/* Display Keyword Density Component */}
              {videoDetails && videoDetails.keyword_density ? (
                <KeywordDensity keywords={videoDetails.keyword_density} />
              ) : (
                <span>No keyword density data available</span>
              )}

              {/* Info Icon */}
              <div style={{ position: 'relative', display: 'inline-block' }}>
                {' '}
                {/* Position context for chart */}
                <i
                  className='fa-regular fa-lightbulb'
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    fontSize: '18px',
                  }}
                  // title='More info about keyword density'
                  onMouseEnter={() => setShowColorChart(true)} // Show chart on hover
                  onMouseLeave={() => setShowColorChart(false)} // Hide chart on mouse leave
                  onClick={() => setShowColorChart((prev) => !prev)} // Toggle chart on click
                />
                {/* Show Color Reference Chart */}
                {showColorChart && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '20px',
                      left: '0',
                      zIndex: '999',
                    }}
                  >
                    <ColorReferenceChart />
                  </div>
                )}
              </div>
            </div>

            <div className='transcription-text-wrapper'>
              {videoDetails && videoDetails.video_transcription ? (
                videoDetails.video_transcription
                  .filter((transcription) => transcription[selectedLanguage])
                  .map((transcription, index) =>
                    transcription[selectedLanguage].map((line, lineIndex) => (
                      <p
                        className='transcriptions-text mb-4'
                        key={`${index}-${lineIndex}`}
                      >
                        {line}
                      </p>
                    ))
                  )
              ) : (
                <span className='transcriptions-text'>
                  00:00:00 - 00:00:00: Transcriptions
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='right-section'>
          {/* Topics */}
          <div className='part2'>
            <div>
              <span className='video-topics-heading'>Topics</span>
            </div>
            <div className='topics-wrapper'>
              <ul className='topics-list'>
                {videoDetails && videoDetails.video_topics ? (
                  videoDetails.video_topics.map((topic, index) => (
                    <li key={index}>
                      <Link
                        to='/topic-play-video'
                        className='topic-link'
                        onClick={() => handleTopicClick(topic)}
                      >
                        {topic.topic_name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li>No topics available</li>
                )}
              </ul>
            </div>
          </div>

          {/* Summary */}
          <div className='part3'>
            <div>
              <span className='video-summary-heading'>Summary</span>
            </div>
            <div className='video-summary-wrapper'>
              <span className='video-summary'>
                {videoDetails ? videoDetails.video_summary : summary}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlay;
