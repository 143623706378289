import React from 'react';

import '../../css/keywordsDensity.css';

const getColorByScore = (score) => {
  if (score >= 50 && score < 60) {
    return '#ABFBB8'; // Color for score 50-60
  } else if (score >= 60 && score < 70) {
    return '#FCD76D'; // Color for score 60-70
  } else if (score >= 70 && score < 80) {
    return '#FFAF88'; // Color for score 70-80
  } else if (score >= 80 && score < 90) {
    return '#BAAEFF'; // Color for score 80-90
  } else if (score >= 90 && score <= 100) {
    return '#FF71DC'; // Color for score 90-100
  } else {
    return 'transparent'; // Default color
  }
};

const KeywordDensity = ({ keywords }) => {
  return (
    <div className='keywords-value-wrapper'>
      {keywords && keywords.length > 0
        ? keywords.map((item, index) => (
            <span
              key={index}
              className='keywords-value'
              style={{
                backgroundColor: getColorByScore(item.score),
              }}
            >
              {item.keyword}
            </span>
          ))
        : 'No keywords available'}
    </div>
  );
};

export default KeywordDensity;
