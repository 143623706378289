import React, { useRef, useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import '../css/landingPage.css';
import VideoCards from '../childComponents/VideoCards';
import Loader from '../childComponents/Loader';
import MultiSelectDropdown from '../childComponents/dropdown/MultiSelectDropdown';
import SingleSelectDropdown from '../childComponents/dropdown/SingleSelectDropdown';
import HashTags from '../childComponents/tags/HashTags';
import GeneralErrorModal from '../childComponents/modals/GeneralErrorModal';

const statusOptions = [
  { name: 'Completed', value: 'completed' },
  { name: 'In Process', value: 'In process' },
  { name: 'Failed', value: 'failed' },
];

const categoryOptions = [
  { name: 'All', value: 'all' },
  { name: 'Lectures & Talks', value: 'lectures & talks' },
  { name: 'Interviews', value: 'interviews' },
  { name: 'Informational & Educational', value: 'informational & educational' },
  { name: 'Documentaries', value: 'documentaries' },
  {
    name: 'Product Reviews & Demonstrations',
    value: 'product reviews & demonstrations',
  },
  { name: 'Presentations & Keynotes', value: 'presentations & keynotes' },
  { name: 'Workshops & Webinars', value: 'workshops & webinars' },
  { name: 'Podcasts', value: 'Podcasts' },
  { name: 'Event Highlights', value: 'event highlights' },
  { name: 'Speeches & Debates', value: 'speeches & debates' },
];

const list = [
  'Lectures & Talks',
  'Interviews',
  'Informational & Educational',
  'Documentaries',
  'Product Reviews & Demonstrations',
  'Presentations & Keynotes',
  'Workshops & Webinars',
  'Podcasts',
  'Event Highlights',
  'Speeches & Debates',
];

const LandingPage = () => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [submittedSearchText, setSubmittedSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem('currentPage');
    return storedPage ? Number(storedPage) : 1;
  });
  const [totalVideos, setTotalVideos] = useState(0); // Store total count of videos
  const [activeButton, setActiveButton] = useState('file');
  const [videoURL, setVideoURL] = useState('');

  const [categoryValues, setCategoryValues] = useState([]);
  const [hashtags, setHashtags] = React.useState([]);
  const [videoStatus, setVideoStatus] = useState('completed');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const [reloadClicked, setReloadClicked] = useState(false);
  const [clearSearchClicked, setClearSearchClicked] = useState(false);
  const [videoFilterSelected, setVideoFilterSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(true);

  const navigate = useNavigate();
  const videosPerPage = 8;

  const fileInputRef = useRef(null); // Create a ref for file input

  useEffect(() => {
    const handleTabClose = () => {
      localStorage.removeItem('currentPage');
    };

    // Listen for tab or window close
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    // Save current page to localStorage whenever it changes
    localStorage.setItem('currentPage', currentPage);
    if (searchText !== '') {
      fetchVideoList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchText === '') {
      fetchVideoList(); // Call fetchVideoList directly here
    }
  }, [videoStatus, selectedCategory, currentPage]);

  // useEffect(() => {
  //   fetchVideoList();
  // }, [videoFilterSelected]);

  useEffect(() => {
    if (reloadClicked === true) {
      fetchVideoList();
    }
    if (clearSearchClicked === true) {
      fetchVideoList();
    }

    if (submittedSearchText !== '') {
      fetchVideoList(); // Reload the video list only after the states are cleared
    }
  }, [reloadClicked, clearSearchClicked, submittedSearchText]);

  useEffect(() => {
    console.log('video List', videoList);
  }, [videoList]);

  useEffect(() => {
    console.log('current toal count', totalVideos);
  }, [totalVideos]);

  // Function to handle clicks on file-input-wrapper
  const handleFileInputClick = (e) => {
    // Check if the click target is not the upload button
    if (!e.target.classList.contains('upload-button')) {
      fileInputRef.current.click(); // Click the file input element
    }
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    setSelectedFile(file); // Set the selected file object
    setSelectedFileName(file.name); // Set the selected file name
    // Additional logic to handle file upload goes here
  };

  // FUnction to handle the URL selection
  const handleURLChange = (e) => {
    setVideoURL(e.target.value); // Update the videoURL state when the user types in the URL input
  };

  const handleVideoUpload = async () => {
    if (videoURL || selectedFile) {
      setLoading(true);
      try {
        toast.info('Uploading video...');

        // Prepare FormData (this will contain both file and body values)
        const formData = new FormData();

        // Append the file if it exists
        if (selectedFile) {
          formData.append('file', selectedFile);
        }

        // Clean hashtags: remove the `#` from each tag, even if the array is empty
        const cleanedHashtags = hashtags.map((tag) => tag.replace('#', ''));
        formData.append('tags', cleanedHashtags); // Always append the tags, even if it's an empty array

        // Append other key-value pairs
        formData.append('video_url', videoURL);
        formData.append('category_value', categoryValues);
        // formData.append('tags', hashtags);
        formData.append('type', activeButton); // Set type based on activeButton state

        console.log('FormData:', Array.from(formData.entries())); // Debugging

        // Send the request with FormData
        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/upload-video`, // Your API endpoint
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Proper Content-Type for file and form data
            },
          }
        );

        if (response.status === 200) {
          if (response.data.msg === 'Video exists') {
            toast.info('Video already exists');
          } else {
            toast.success('File Uploaded and Processing');
            // Fetch updated video list
            fetchVideoList();
          }
          // toast.success('Video Uploaded Successfully');
          // fetchVideoList(); // Fetch updated video list
        } else {
          toast.error('Error uploading video');
        }
      } catch (error) {
        console.error('Error uploading video:', error);
        toast.error('Error uploading video');
      } finally {
        setLoading(false);
        setVideoURL(''); // Reset the URL input field
        setSelectedFile(null); // Reset the File input field
        setSelectedFileName(''); // Reset the selected file name
        setCategoryValues([]); // Reset the Category input field
        setHashtags([]); // Reset the HasTags input field
      }
    } else {
      alert('Please enter a video URL or select a file to upload');
    }
  };

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedValues = typeof value === 'string' ? value.split(',') : value;

    if (selectedValues.length <= 3) {
      setCategoryValues(selectedValues);
    } else {
      // Optionally, show an alert or a toast message indicating the max selection
      alert('You can only select up to 3 categories.');
    }
  };

  // Function to handle card click and store title in local storage
  const handleCardClick = async (title, video_id, status) => {
    if (status === 'completed') {
      localStorage.setItem('videoTitle', title);
      localStorage.setItem('videoId', video_id);
      navigate('/play-video');
    } else if (status === 'In Process') {
      toast.info('Video is still processing');
    } else if (status === 'Failed') {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/process-video?videoId=${video_id}`
      );
      console.log(response.data.msg);
      setErrorMessage(response.data.msg);
      setShowModal(true); // Show modal
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal when OK button is clicked
  };

  // Fetch video list on page load and at intervals
  const fetchVideoList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/video-list?search=${searchText}&page_number=${currentPage}&category=${selectedCategory}&status=${videoStatus}`
      );
      if (response.data.records.length === 0) {
        toast.info('No videos found.');
        setVideoList(response.data.records);
        setTotalVideos(response.data.total_video_count);
        // setSearchText('');
      } else {
        setVideoList(response.data.records);
        setTotalVideos(response.data.total_video_count);
        // setSearchText('');
      }
    } catch (error) {
      console.error('Error fetching video list:', error);
      toast.error('Error fetching video list');
    } finally {
      setLoading(false); // Stop loader

      if (reloadClicked === true) {
        setReloadClicked(false);
      }
      if (clearSearchClicked === true) {
        setClearSearchClicked(false);
      }
      if (videoFilterSelected === true) {
        setVideoFilterSelected(false);
      }
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < Math.ceil(totalVideos / videosPerPage)) {
      setCurrentPage(currentPage + 1);
      // localStorage.setItem('currentPage', currentPage + 1);
    }
  };

  const handlePrevPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      // localStorage.setItem('currentPage', currentPage - 1);
    }
  };

  // Helper function to format the video title
  const formatTitle = (title) => {
    // Remove the extension
    const nameWithoutExtension = title.replace(/\.[^/.]+$/, '');
    // Replace underscores with spaces
    const nameWithSpaces = nameWithoutExtension.replace(/_/g, ' ');
    // Capitalize the first letter
    return nameWithSpaces.charAt(0).toUpperCase() + nameWithSpaces.slice(1);
  };

  // Function to get the required video cards
  const getRequiredVideoCards = () => {
    return videoList.slice(0, 8);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setVideoStatus('completed');
    setSelectedCategory('all');
    // fetchVideoList();
    setSubmittedSearchText(searchText);
  };

  const clearSearch = () => {
    setClearSearchClicked(true);
    setSearchText(''); // Clear search input
    setSubmittedSearchText('');
    setCurrentPage(1); // Reset pagination to page 1
    // fetchVideoList(); // Reload the video list without search query
  };

  const reloadAPI = () => {
    setReloadClicked(true);
    setCurrentPage(1);
    setSearchText('');
    setSubmittedSearchText('');
    setVideoStatus('completed');
    setSelectedCategory('all');
    // fetchVideoList();
  };

  return (
    <>
      <Navbar />
      {showModal && (
        <GeneralErrorModal
          errorMessage={errorMessage}
          onClose={handleCloseModal}
          errorMessageHeading={'Failed to process the video'}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className='min-h-screen flex flex-col items-center bg-gray-100'>
          <div className='mt-8 justify-center'>
            <div className='text-center'>
              <h1 className='text-3xl font-bold'>
                <span className='heading-highlight'>SpotlightAI </span>
                <span className='heading-non-highlight'>
                  AI-driven Topic Segmentation
                </span>
              </h1>
            </div>

            {/* Upload Disabled Notice */}
            <div className='text-center mt-4'>
              <p className='text-red-400 font-semibold'>
                * The upload option is disabled. Please select the pre-uploaded
                videos.
              </p>
            </div>

            {/* Switch Buttons */}
            <div className='switch-button-wrapper items-center flex justify-center gap-4'>
              <button
                className={`button-file-upload ${
                  activeButton === 'file' ? 'active-button' : 'inactive-button'
                }`}
                onClick={() => setActiveButton('file')}
              >
                Upload a video file
              </button>

              <button
                className={`button-url-upload ${
                  activeButton === 'url' ? 'active-button' : 'inactive-button'
                }`}
                onClick={() => setActiveButton('url')}
              >
                Upload a video URL
              </button>
            </div>
            <div className='text-center'>
              <p className='sub-heading'>
                Unlock the full potential of long-session videos with precise
                transcriptions, key topic extraction, and <br />
                engaging short segments.
              </p>
            </div>

            {/* Upload File/Url Input Section */}
            <div className='mt-6 flex items-center justify-center gap-1'>
              {/* File Input */}
              {activeButton === 'file' ? (
                <div
                  className='file-input-wrapper flex items-center justify-between relative'
                  onClick={handleFileInputClick}
                >
                  <div className='flex items-center upload-text'>
                    <i className='fa-solid fa-cloud-arrow-up mr-2'></i>
                    <span className=''>
                      {selectedFileName || 'Click here to upload from local...'}
                    </span>
                    {/* Hidden file input */}
                    <input
                      ref={fileInputRef}
                      type='file'
                      className='hidden'
                      onChange={handleFileChange}
                    />
                  </div>
                  <button
                    className='px-4 py-2 upload-button disabled:opacity-50 disabled:cursor-not-allowed'
                    onClick={handleVideoUpload}
                    disabled={areButtonsDisabled}
                  >
                    Upload File
                  </button>
                </div>
              ) : (
                ''
              )}
              {/* URL Input */}
              {activeButton === 'url' ? (
                <div className='file-input-wrapper flex items-center justify-between relative'>
                  <div className='flex items-center upload-text url-input-wrapper'>
                    <i className='fa-solid fa-link mr-2'></i>

                    {/* Text input for URL */}
                    <input
                      type='text'
                      className='url-input ml-2'
                      placeholder='Enter video URL'
                      value={videoURL}
                      onChange={handleURLChange}
                    />
                  </div>
                  <button
                    className='px-4 py-2 upload-button disabled:opacity-50 disabled:cursor-not-allowed'
                    onClick={handleVideoUpload}
                    disabled={areButtonsDisabled}
                  >
                    Upload URL
                  </button>
                </div>
              ) : (
                ''
              )}
              <div>
                <MultiSelectDropdown
                  value={categoryValues}
                  setValue={setCategoryValues}
                  handleChange={handleCategoryChange}
                  list={list}
                />
              </div>
              <div>
                <HashTags hashtags={hashtags} setHashtags={setHashtags} />
              </div>
            </div>

            {/* Middle Part */}
            <div className='flex justify-center items-center'></div>
            {videoList.length > 0 && (
              <div className='flex justify-center'>
                <div className='video-list-wrapper justify-center items-center'>
                  <div className='flex justify-between items-center search-div'>
                    <div>
                      <span className='video-list-box-heading'>All Videos</span>
                    </div>
                    {/* Dropdowns And Search Box*/}
                    <div className='flex justify-center items-center'>
                      <div>
                        <SingleSelectDropdown
                          lable={'Status'}
                          value={videoStatus}
                          setValue={setVideoStatus}
                          list={statusOptions}
                          sx={{
                            '& .MuiSelect-select': {
                              padding: '10px 32px 10px 15px !important',
                            },
                            // You can add more custom styles here if needed
                          }}
                          disabled={!!submittedSearchText}
                        />
                      </div>
                      <div>
                        <SingleSelectDropdown
                          lable={'Category'}
                          value={selectedCategory}
                          setValue={setSelectedCategory}
                          list={categoryOptions}
                          sx={{
                            '& .MuiSelect-select': {
                              padding: '10px 32px 10px 15px !important',
                            },
                            // You can add more custom styles here if needed
                          }}
                          disabled={!!submittedSearchText}
                        />
                      </div>
                      <div className='relative search-text-box'>
                        <input
                          type='text'
                          className='rounded-md pl-4 pr-10 py-2 w-full search-text-box-input'
                          placeholder='Search...'
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch(); // Trigger search when "Enter" key is pressed
                            }
                          }}
                        />
                        <i
                          className='fa-solid fa-magnifying-glass absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                          onClick={handleSearch}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {/* Searched Text Display */}
                  {submittedSearchText && (
                    <div className='mt-4 flex items-center justify-between bg-gray-100 p-2 rounded-md shadow-sm'>
                      <span className='text-gray-800 font-semibold ml-12'>
                        Searched for:{' '}
                        <span className='text-blue-600'>
                          {submittedSearchText}
                        </span>
                      </span>
                      <button
                        onClick={clearSearch}
                        className='text-red-500 hover:text-red-700 focus:outline-none ml-4'
                      >
                        <i className='fas fa-times'></i>
                      </button>
                    </div>
                  )}

                  {/* Video Cards */}
                  <div className='mt-4 flex flex-wrap justify-center md:justify-center card-component-wrapper gap-5'>
                    {getRequiredVideoCards().map((video) => (
                      <div className='m-2' key={video.video_id}>
                        <div
                          to='/play-video'
                          onClick={() =>
                            handleCardClick(
                              formatTitle(video.video_title),
                              video.video_id,
                              video.status
                            )
                          }
                        >
                          <VideoCards
                            imgSrc={
                              video.thumbnail_url ||
                              'https://via.placeholder.com/150'
                            }
                            // title={video.video_title}
                            title={formatTitle(video.video_title)}
                            duration={video.duration}
                            status={video.status}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* Pagination  */}
            {videoList.length > 0 && (
              <div className='pagination-buttons-wrapper flex justify-center gap-4 mb-10 items-center'>
                <button
                  className='prev-button disabled:cursor-not-allowed'
                  disabled={currentPage === 1}
                  onClick={handlePrevPageClick}
                >
                  <i className='fa-solid fa-angle-left mr-1'></i> PREV
                </button>
                <span className='page-number-display px-4 py-2 bg-blue-100 text-blue-800 rounded-md font-semibold'>
                  {currentPage}
                </span>

                <button
                  className='next-button disabled:cursor-not-allowed'
                  onClick={handleNextPageClick}
                  disabled={
                    currentPage >= Math.ceil(totalVideos / videosPerPage)
                  }
                >
                  NEXT <i className='fa-solid fa-angle-right ml-1'></i>
                </button>
              </div>
            )}

            {videoList.length === 0 && (
              <div className='reload-icon-wrapper flex justify-center'>
                <span>
                  <i
                    className='fa-solid fa-rotate-right reload-icon'
                    onClick={reloadAPI}
                  ></i>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;
