// ColorReferenceChart.js
import React from 'react';

import '../css/keywordDensityReferenceChart.css';

const colorRanges = [
  { range: '50% - 60%', color: '#ABFBB8' },
  { range: '60% - 70%', color: '#FCD76D' },
  { range: '70% - 80%', color: '#FFAF88' },
  { range: '80% - 90%', color: '#BAAEFF' },
  { range: '90% - 100%', color: '#FF71DC' },
];

const ColorReferenceChart = () => {
  return (
    <div className='color-reference-chart'>
      <h4 className='keyword-density-popup-label'>
        Keyword Density Color Code:
      </h4>
      <ul className='list-style'>
        {colorRanges.map((item, index) => (
          <li key={index} className='list-item-style'>
            <span
              className='keyword-chart-color-codes'
              style={{
                backgroundColor: item.color,
              }}
            ></span>
            {item.range}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ColorReferenceChart;
