import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SingleSelectDropdown = ({
  lable,
  value,
  setValue,
  list,
  sx,
  disabled,
}) => {
  // const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // const list = [
  //   { name: 'Completed', value: 'completed' },
  //   { name: 'In Process', value: 'In process' },
  //   { name: 'Failed', value: 'failed' },
  // ];

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id='demo-simple-select-autowidth-label'>{lable}</InputLabel>
        <Select
          labelId='demo-simple-select-autowidth-label'
          id='demo-simple-select-autowidth'
          value={value}
          onChange={handleChange}
          autoWidth
          label={lable}
          sx={sx}
          disabled={disabled}
        >
          {list.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SingleSelectDropdown;
