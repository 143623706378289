// GerenalErrorModal.js
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import '../../css/generalErrorModal.css'; // Assuming you have a separate CSS file for modal styling

const GerenalErrorModal = ({ errorMessage, onClose, errorMessageHeading }) => {
  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <FontAwesomeIcon icon={faExclamationCircle} className='error-icon' />
        <p className='heading-error-message'>{errorMessageHeading}</p>
        <p className='error-message'>{errorMessage}</p>
        <button onClick={onClose} className='modal-close-btn'>
          Close
        </button>
      </div>
    </div>
  );
};

GerenalErrorModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GerenalErrorModal;
