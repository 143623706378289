import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const HashTags = ({ hashtags, setHashtags }) => {
  // const [hashtags, setHashtags] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      if (hashtags.length < 3) {
        setHashtags([...hashtags, `#${inputValue.trim()}`]);
        setInputValue('');
      } else {
        alert('You can only add up to 3 hashtags');
      }
    }
  };

  const handleDelete = (hashtagToDelete) => () => {
    setHashtags((hashtags) =>
      hashtags.filter((hashtag) => hashtag !== hashtagToDelete)
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 300 }}>
      <TextField
        label='Enter Hashtags'
        variant='outlined'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder='Type and press enter...'
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
          mt: 0.5,
          p: 0,
          border: 'none',
          borderRadius: 2,
          backgroundColor: 'transparent',
        }}
      >
        {hashtags.map((hashtag, index) => (
          <Chip
            key={index}
            label={hashtag}
            onDelete={handleDelete(hashtag)}
            deleteIcon={<CloseIcon />}
          />
        ))}
      </Box>
    </Box>
  );
};

export default HashTags;
