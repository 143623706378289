import React from 'react';
import '../css/videoCards.css';
import '../css/videoTopicCard.css';

const VideoTopicCard = ({ src, title, duration }) => {
  return (
    <div className='topic-video-card-main-div relative'>
      <div className='flex justify-center pt-3 relative'>
        <img
          src={src}
          alt={title}
          className='w-full object-cover topic-video-thumbnail'
        />
        {/* Duration Displayed on Bottom Left of Thumbnail */}
        <div className='absolute bottom-0 left-1 video-duration'>
          {duration}
        </div>
      </div>

      {/* Truncated Title with Hover Effect */}
      <h2 className='mt-1 topic-video-card-title' title={title}>
        {title}
      </h2>
    </div>
  );
};

export default VideoTopicCard;
