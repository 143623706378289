import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../img/logo.png';
import spotlight_color_logo_transparent_bg from '../img/spotlight-color-logo-transparent-bg.png';
import spotlight_ai_color_logo_transparent_bg from '../img/spotlight-ai-color-logo-transparent-bg.png';
import '../css/navbar.css';

const Navbar = () => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState('Overview / Project');

  // useEffect(() => {
  //   const path = location.pathname;
  //   const videoName = localStorage.getItem('videoTitle') || 'Video Name';
  //   const videoTopic = localStorage.getItem('videoTopic') || 'Topic Name';

  //   const getBreadcrumb = (path) => {
  //     switch (true) {
  //       case path.startsWith('/play'):
  //         return (
  //           <>
  //             <span className='text-non-highlight'>Overview / </span>
  //             <span className='text-non-highlight'>Project / </span>
  //             <span className='text-highlight'>{videoName}</span>
  //           </>
  //         );
  //       case path.startsWith('/topic-play'):
  //         return (
  //           <>
  //             <span className='text-non-highlight'>Overview / </span>
  //             <span className='text-non-highlight'>Project / </span>
  //             <span className='text-non-highlight'>{videoName} / </span>
  //             <span className='text-highlight'>{videoTopic}</span>
  //           </>
  //         );
  //       default:
  //         return (
  //           <>
  //             <span className='text-non-highlight'>Overview / </span>
  //             <span className='text-highlight'>Project</span>
  //           </>
  //         );
  //     }
  //   };
  //   setBreadcrumb(getBreadcrumb(path));
  // }, [
  //   location.pathname,
  //   localStorage.getItem('videoTitle'),
  //   localStorage.getItem('videoTopic'),
  // ]);

  useEffect(() => {
    const path = location.pathname;
    const videoName = localStorage.getItem('videoTitle') || 'Video Name';
    const videoTopic = localStorage.getItem('videoTopic') || 'Topic Name';

    const getBreadcrumb = (path) => {
      switch (true) {
        case path === '/':
          return (
            <>
              <span className='text-highlight'>Homepage</span>
            </>
          );
        case path.startsWith('/play'):
          return (
            <>
              {/* <span className='text-non-highlight'>Overview / </span> */}
              <span className='text-highlight'>Video Detail</span>
            </>
          );
        case path.startsWith('/topic-play'):
          return (
            <>
              {/* <span className='text-non-highlight'>Overview / </span> */}
              {/* <span className='text-non-highlight'>{videoName} / </span> */}
              <span className='text-highlight'>Video Topics</span>
            </>
          );
        default:
          return (
            <>
              <span className='text-non-highlight'>Overview / </span>
              {/* <span className='text-highlight'>Project</span> */}
            </>
          );
      }
    };

    setBreadcrumb(getBreadcrumb(path));
  }, [
    location.pathname,
    localStorage.getItem('videoTitle'),
    localStorage.getItem('videoTopic'),
  ]);
  return (
    <div>
      <div className='navbar-wrapper p-4 flex justify-between items-center'>
        <div className='flex items-center'>
          <Link to='/'>
            <img
              src={spotlight_ai_color_logo_transparent_bg}
              alt='Logo'
              className='logo'
            />
          </Link>
        </div>
        <div className='flex-grow text-center bredcum-text-wrapper'>
          {breadcrumb}
        </div>
        <div className='flex items-center'>
          {/* Additional items (if any) can go here */}
        </div>
      </div>
      <hr className='mt-2 navbar-hr' />
    </div>
  );
};

export default Navbar;
