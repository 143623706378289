import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Navbar from '../components/Navbar';

import '../css/videoPlay.css';
import '../css/topicVideoPlay.css';

import sample_video from '../img/sample_video.mp4';
import VideoTopicCard from '../childComponents/VideoTopicCard';

const summary =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';

const TopicVideoPlay = () => {
  const navigate = useNavigate();

  const [videoDetails, setVideoDetails] = useState([]);
  const [topicCount, setTopicCount] = useState(0);
  const [topicTitle, setTopicTitle] = useState('');
  const [videoTopics, setVideoTopics] = useState([]);
  const [topicVideoUrl, setTopicVideoUrl] = useState('');
  const [topicVideoSummary, setTopicVideoSummary] = useState('');
  const [showAllTopics, setShowAllTopics] = useState(false); // New state for showing all topics

  // const [topicUrl, setTopicUrl] = useState('');
  // const [topicSummary, setTopicSummary] = useState('');

  useEffect(() => {
    const videoId = localStorage.getItem('videoId'); // Fetch videoId from local storage

    if (videoId) {
      const fetchVideoDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URI}/api/process-video?videoId=${videoId}`
          );
          setVideoDetails(response.data);

          const storedTopic = localStorage.getItem('videoTopic');
          if (storedTopic) {
            const selectedTopic = response.data.video_topics.find(
              (topic) => topic.topic_name === storedTopic
            );
            if (selectedTopic) {
              setTopicVideoUrl(selectedTopic.topic_video_url);
              setTopicVideoSummary(selectedTopic.topic_summary);
            }
          }

          setVideoTopics(response.data.video_topics);
        } catch (error) {
          console.error('Error fetching video details:', error);
          toast.error('Error fetching video details');
        }
      };

      fetchVideoDetails();
    } else {
      toast.error('No video found');
    }
  }, []);

  // Function to handle card click, store title in local storage and send API request
  const handleCardClick = async (topic) => {
    try {
      // await axios.post('/api/videoName', { videoName: topic });
      localStorage.setItem('videoTopic', topic);
      window.location.reload();
    } catch (error) {
      toast.error('Error sending video name');
    }
  };

  useEffect(() => {
    const storedTopicCount = localStorage.getItem('topicCount');
    if (storedTopicCount) {
      setTopicCount(parseInt(storedTopicCount, 10));
    }
    const topicName = localStorage.getItem('videoTopic');
    if (topicName) {
      setTopicTitle(topicName);
    }
    // const topicVideoUrl = localStorage.getItem('topicvideoUrl');
    // if (topicVideoUrl) {
    //   setTopicUrl(topicVideoUrl);
    // }
    // const topicSumamry = localStorage.getItem('topicSummary');
    // if (topicSumamry) {
    //   setTopicSummary(topicSumamry);
    // }
  }, []);

  useEffect(() => {
    const storedTopicCount = localStorage.getItem('topicCount');
    if (storedTopicCount) {
      setTopicCount(parseInt(storedTopicCount, 10));
    }
    const topicName = localStorage.getItem('videoTopic');
    if (topicName) {
      setTopicTitle(topicName);
    }
  }, []);

  const showAllVideos = () => {};

  const toggleShowAllTopics = () => {
    setShowAllTopics((prev) => !prev); // Toggle the state
  };

  if (videoTopics) {
    console.log('video Topics', videoTopics);
  }

  if (topicVideoUrl) {
    console.log('video URL', topicVideoUrl);
  }

  return (
    <>
      <Navbar />
      <div className='container-topic-videos'>
        {/* Part 1 - Side by side divs */}
        <div className='part1-topic-videos'>
          <div className='left-area-media-player'>
            {/* Content for left area */}
            <div className='media-player'>
              {videoDetails && topicVideoUrl ? (
                <>
                  <h2 className='topic-title'>{topicTitle}</h2>{' '}
                  {/* Displaying the topic title here */}
                  <video controls className='video-element'>
                    <source src={topicVideoUrl} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <p>Loading video...</p>
              )}
            </div>
          </div>
          <div className='right-area-summary'>
            {/* Content for right area */}
            <div className='part-video-topic'>
              <div>
                <span className='video-summary-heading'>Summary</span>
              </div>
              <div className='video-summary-wrapper'>
                <span className='video-summary'>{topicVideoSummary}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Part 2 - Below part 1 */}
        <div className='video-topic-part2'>
          {/* Content for part 2 */}
          {/* New div inside Part 2 */}
          <div className='part2-content'>
            {/* Content for part 2 */}
            <div className='video-cards-headers'>
              <span className='topic-video-heading'>Topic Videos</span>
              {videoTopics.length > 5 && (
                <span
                  className='all-videos-button'
                  onClick={toggleShowAllTopics}
                >
                  {showAllTopics ? 'View Less' : 'View All'}
                </span>
              )}
            </div>
            <div className='topic-video-card-wrapper'>
              {(showAllTopics ? videoTopics : videoTopics.slice(0, 5)).map(
                (topic, index) => (
                  <div className='' key={index}>
                    <Link
                      to='/topic-play-video'
                      onClick={() => handleCardClick(topic.topic_name)}
                    >
                      <VideoTopicCard
                        src={topic.topic_thumbnail_url}
                        title={topic.topic_name}
                        duration={topic.topic_duration}
                      />
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicVideoPlay;
