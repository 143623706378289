import { Fragment, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import '../src/App.css';
import './css/global.css';

// Redux
import LandingPage from './components/LandingPage';
import store from './store';
import Navbar from './components/Navbar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS
import VideoPlay from './components/VideoPlay';
import TopicVideoPlay from './components/TopicVideoPlay';

// import Loader from './components/loader/Loader';
// import Alert from './components/layout/alert/Alert';
// import Login from './components/onBoarding/Login';

const App = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {/* <Navbar></Navbar> */}
          <section className='pr-0 pl-0 bg-all'>
            <ToastContainer /> {/* Include ToastContainer */}
            <Routes>
              <Route exact path='/' element={<LandingPage />} />
              <Route exact path='/play-video' element={<VideoPlay />} />
              <Route
                exact
                path='/topic-play-video'
                element={<TopicVideoPlay />}
              />
            </Routes>
          </section>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
